

.faq-inner-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url('../../src/images/enclosure1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: fit-content;
  background-attachment: fixed;
}

.locations {
  display: flex;
flex-direction: row;
padding: 1rem;
width: 50%;
justify-content: space-between;
  
}

.FAQ-header {
  padding: 0.5rem;
}

.faq-item {
  display: flex;
  flex-direction: column;
  width: 70%;
  background-color: #dcdcdd;
  list-style-position:inside;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 3px;
	-webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
	        box-shadow: 0 0 15px rgba(0,0,0,0.3)
}

.faq-item>h2 {
  display: flex;
  width: 100%;
  margin: 0 0 15px;
  font-size: large;
  font-weight: 600;
  line-height: 1.75rem;
  padding: 0.5rem;
  border-radius: 3px;
	-webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
	        box-shadow: 0 0 15px rgba(0,0,0,0.3);
}


@media (max-width: 900px) {
}

@media (max-width: 765px) {

  .locations {
    display: flex;
  flex-direction: column;
  }

  .FAQ-header {
    padding: 0.5rem;
    font-size: 1rem;
  }

  .faq-item {
    margin-top: 0.5rem;
    padding: 0.5rem;
    display: flex;
    width:  100%;
    height: auto;
  
  }

  .faq-item>h2 {
    display: flex;
    width: 100%;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0.5rem;
  }

  .faq-item>p {
    display: flex;
font-size: 1rem;
  }

}
  