

.container-services {
    background: url('../../src/images/enclosure1.jpg') no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    background-attachment: fixed;
}



.header-container {
    background-color: #E5E5E5;
    display: flex;
    justify-items: center;
    padding: 2rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
border-radius: 3px;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
          box-shadow: 0 0 15px rgba(0,0,0,0.3)
}

.svg-icon {
    display: flex;
    height: auto;
    width: 100px;
    margin: 0%;
    color: black;
}

.card-container {
    display: grid;
 height: fit-content;
    width: 100%;
    grid-template-columns: 0.1fr repeat(3, 1fr) 0.1fr;
    grid-template-rows: repeat(2, 450px);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    padding: 4rem;
}
  .card-item-2 > h2 {
        display: flex;
       text-align: center;
    }

.card-item-1{
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
          box-shadow: 0 0 15px rgba(0,0,0,0.3)
}

.card-item-2{
    grid-column: 3 / 3;
    grid-row: 1 / 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
        justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
          box-shadow: 0 0 15px rgba(0,0,0,0.3)
}
.card-item-3{
    grid-column: 4 / 4;
    grid-row: 1 / 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
          box-shadow: 0 0 15px rgba(0,0,0,0.3)
}
.card-item-4{
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
          box-shadow: 0 0 15px rgba(0,0,0,0.3)
}
.card-item-5{
    grid-column: 3 / 3;
    grid-row: 2 / 2;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
          box-shadow: 0 0 15px rgba(0,0,0,0.3)
}

.card-item-6{
    grid-column: 4 / 4;
    grid-row: 2 / 2;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
          box-shadow: 0 0 15px rgba(0,0,0,0.3)
}




@media screen and (max-width: 960px) {

.card-container{
    display: flex;
    width: 90%;
    height: auto;
    flex-direction: column;
    margin: auto;
    padding: 2rem 0;
}

}

@media screen and (max-width: 768px) {

    .card-container {
        display: flex;
        width: 90%;
        height: auto;
        flex-direction: column;
        margin: auto;
        padding: 2rem 0;
    }

}