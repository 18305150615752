.site-footer {
  background: #242f40;
  display: flex;
  height: fit-content;
position: relative;
bottom: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem ;
  text-align: center;
  color: white;

}



.footer > h2 {
  color: white;
  font-size: 2rem;
}

.footer > h4 {
  color: white;
  font-size: large;
  font-style: unset;
}


 .site-footer > h4{
  display: flex;
  flex-direction: nowrap;
  align-items: center;
}


.site-footer a {
  color: white;
  text-decoration: none;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links li {
  display: flex;
  justify-content: center;
  align-items: center;
}


.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block
}

.site-footer .social-icons {
  text-align: right;
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-bottom: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #eceeef;
}

.copyright-text {
  margin: 0
}


@media (max-width:767px) {

  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center
  }
}

.social-icons {
  margin-bottom: 0;
  padding-top: 1rem;
  list-style: none
}

.social-icons li {
  display: flex;
  margin-bottom: 4px
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px
}

.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px
}

.social-icons a.facebook:hover {
  background-color: #3b5998
}

.social-icons a.twitter:hover {
  background-color: #00aced
}

.social-icons a.linkedin:hover {
  background-color: #007bb6
}

.social-icons a.yelp:hover {
  background-color: #ea4c89
}

@media (max-width:767px) {

.footer-item {
  padding: 0 0.75rem; 
}

  .social-icons {
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
  }


  .site-footer {
    display: 'flex';
    height: fit-content;
    width: 100%;
    font-size: smaller;
    padding: env(safe-area-inset-bottom, 50px);
overflow-y: hidden;
      }

      .site-footer .social-icons {
        display: flex;
      }
  }

