.gallery {background-color: #fff;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  background-attachment: fixed;
}

.single-skeleton {
  border-radius: 10px;

}

.single {
  display: flex;
  border-radius: 2rem;

}

#gallery-header  {
    text-align: center;
        font-size:40px; font-weight:500; color:#242f40; letter-spacing:2px;
        text-transform: uppercase;
        display: grid;
        grid-template-columns: 1fr max-content 1fr;
        grid-template-rows: 27px 0;
        grid-gap: 20px;
        align-items: center;
    }
    
    #gallery-header:after, #gallery-header:before {
        content: " ";
        display: block;
        border-bottom: 1px solid  #242f40;
        border-top: 1px solid #242f40;
        height: 5px;
      background-color:#f8f8f8;
    }


.galleryWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin: auto;
    background-color: none;
    padding: 2rem 1rem;
  }
  
  .galleryWrap .single {
    display: flex;
    width: 25%;
    height: 300px;
    background: none;
    cursor: pointer;
    object-fit: fill;
    justify-content: center;
    float: left;
  }

  
  .galleryWrap .single img {
    max-width: 100%;
    max-height: auto;
  }
  
  .galleryWrap .single img:hover {
    transform: scale(1.02);
      box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  
  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
    width: 50px;
    height: 50px;

  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 40px;
    right: 40px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
  }

  img ~ * {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
    @media screen and (max-width: 768px){

      #gallery-header {
        font-size: 100%;
      }
     
      .galleryWrap {
     flex-direction: column;
     width: 100%;
     margin: auto;
    }

      .galleryWrap .single {
        width: 100%;
      }
    }