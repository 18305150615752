:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20 px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 30px;
}

.btn--medium:hover, .btn--large:hover {
    background: white;
    color: #242424;
    transition: 250ms;
}
.navbar {
  background: #242f40;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  max-width: 98%;
}

.navbar-logo {
  margin-left: 20px;
  cursor: pointer;
  display: flex;
}

.navbar-brand {
display: flex;
width: auto;
height: 95%;
margin: auto;
}


.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

#logo {
display: flex;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }



  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #fff;
    color: #242424;
    border-radius: 0;
  }


  .menu-icon {
    display: block;
    position: absolute;
    top: 12.5px;
    right: 0;
    -webkit-transform: translate(-100%, 60%);
            transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
}



.cards {
  padding: 2rem;
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  height: auto;
  width: 90%;
  margin: 1rem auto;
}

.cards__wrapper {
  position: relative;
  margin: auto 2rem;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 0.75 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  -webkit-animation-name: fade-img;
          animation-name: fade-img;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: #252e48;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}
  @media screen and (max-width: 768px){

    .cards {
      padding: 1rem;
    }
    

    .cards__item{
      width: 100%;
      align-items: center;
      margin: 1rem 0;
      flex: 1 1;
    }
}


:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20 px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 30px;
}

.btn--medium:hover, .btn--large:hover {
    background: white;
    color: #242424;
    transition: 250ms;
}
  .hero-container {
    background: url(/static/media/enclosure1.137a723f.jpg) center center/cover no-repeat;
    height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    font-size: large;
  }


.hero-image {
display: flex;
max-width: 80%;
height: auto;
padding-bottom: 1rem;

}
  
  .hero-container > p {
    color: #fff;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
      display: flex;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -125px;
    }
  }
  
  @media screen and (max-width: 768px) {

    .hero-container {
    background: url(/static/media/enclosure1.137a723f.jpg) 25% center/cover no-repeat;
    }

    .hero-container > h1 {
      font-size: 50px;
  
    }

    .hero-image {
      display: flex;
      max-width: 60%;
      height: auto;
      
      }
  
    .hero-container > p {
      font-size: 20px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }


.container-services {
    background: url(/static/media/enclosure1.137a723f.jpg) no-repeat center center fixed;
    background-size: cover;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-position: center;
    width: 100%;
    min-height: 100vh;
    background-attachment: fixed;
}



.header-container {
    background-color: #E5E5E5;
    display: flex;
    justify-items: center;
    padding: 2rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
border-radius: 3px;
  box-shadow: 0 0 15px rgba(0,0,0,0.3)
}

.svg-icon {
    display: flex;
    height: auto;
    width: 100px;
    margin: 0%;
    color: black;
}

.card-container {
    display: grid;
 height: -webkit-fit-content;
 height: -moz-fit-content;
 height: fit-content;
    width: 100%;
    grid-template-columns: 0.1fr repeat(3, 1fr) 0.1fr;
    grid-template-rows: repeat(2, 450px);
    grid-column-gap: 2rem;
    grid-row-gap: 2rem;
    padding: 4rem;
}
  .card-item-2 > h2 {
        display: flex;
       text-align: center;
    }

.card-item-1{
    grid-column: 2 / 2;
    grid-row: 1 / 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  box-shadow: 0 0 15px rgba(0,0,0,0.3)
}

.card-item-2{
    grid-column: 3 / 3;
    grid-row: 1 / 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
        justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  box-shadow: 0 0 15px rgba(0,0,0,0.3)
}
.card-item-3{
    grid-column: 4 / 4;
    grid-row: 1 / 1;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  box-shadow: 0 0 15px rgba(0,0,0,0.3)
}
.card-item-4{
    grid-column: 2 / 2;
    grid-row: 2 / 2;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  box-shadow: 0 0 15px rgba(0,0,0,0.3)
}
.card-item-5{
    grid-column: 3 / 3;
    grid-row: 2 / 2;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  box-shadow: 0 0 15px rgba(0,0,0,0.3)
}

.card-item-6{
    grid-column: 4 / 4;
    grid-row: 2 / 2;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
background-color: #E5E5E5;
border-radius: 3px;
  box-shadow: 0 0 15px rgba(0,0,0,0.3)
}




@media screen and (max-width: 960px) {

.card-container{
    display: flex;
    width: 90%;
    height: auto;
    flex-direction: column;
    margin: auto;
    padding: 2rem 0;
}

}

@media screen and (max-width: 768px) {

    .card-container {
        display: flex;
        width: 90%;
        height: auto;
        flex-direction: column;
        margin: auto;
        padding: 2rem 0;
    }

}

.container-gallery {
	background-color: #fff;
	padding-bottom: 3rem;
    background-size: cover;
    padding: 2rem;

}

.wrap {

	display: grid;
	padding: 3rem auto;
	grid-template-columns: 0.1fr repeat(5, 1fr) 0.1fr;
	grid-column-gap: 1rem;

}

.box {
	margin: 2rem auto;
	width: 100%;
	height: 80vh;
	text-align: center;
	text-decoration: none;
	border-radius: 10px;

}

.box:hover {
	margin-bottom: -5px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

	-webkit-transform: scale(1.015);

	        transform: scale(1.015);

}


.box h1 {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	color: #fff;
	height: 20%;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
	padding: 5px;
	text-align: center;
	font-weight: 100;
	font-size: 30px;
	background: rgba(0, 0, 0, 0.8);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}


.one {
	grid-column: 2 / 2;
	background: url(/static/media/frameless4.0521aaab.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	object-fit: fill;
	background-position: center;

}

.two {
	grid-column: 3 / 3;
	background: url(/static/media/semi1.0ffa3fd4.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	object-fit: scale-down;
	background-position: center;
}

.three {
	grid-column: 4 / 4;
	background: url(/static/media/sliding1.15dcf364.jpg);
	background-repeat: no-repeat;
	background-size: cover ;
	object-fit: scale-down;
	background-position: center;
}

.five {
	grid-column: 5 / 5;
	background: url(/static/media/mirror12.35782488.jpg);
	background-repeat: no-repeat;
	background-size: cover; 
	background-position:   right;
}

.six {
	grid-column: 6 / 6;
	background: url(/static/media/wine1.3e959d6f.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}


@media screen and (max-width: 1200px) {
	.wrap {
		display: grid;
		width: 100%;
		display: grid;
		grid-template-columns: 5% repeat(2, 40%) 5%;
		grid-template-rows: repeat(3, 1fr);
		grid-column-gap: 20px;
		-webkit-column-gap: 20px;
		        column-gap: 20px;
		grid-row-gap: 20px;
		row-gap: 20px;
	}

	.box {
		width: 100%;
	}

	.one {
		grid-column: 2 / 2;
		grid-row: 1 / 1;
	}


	.two {
		grid-column: 3 / 3;
		grid-row: 1 / 1;
	}


	.three {
		grid-column: 2 / 2;
		grid-row: 2 / 2;
	}


	.four {
		grid-column: 3 / 3;
		grid-row: 2 / 2;
	}

	.five {
		grid-column: 3 / 3;
		grid-row: 2 / 2;
	}

	.six {
		grid-column: 2 / 2;
		grid-row: 3 / 3;
	}
}

@media screen and (max-width: 480px) {
	.wrap {
		display: flex;
		flex-direction: column;
		padding: 1rem 0;
	}

	.box {
		height: calc((100vh - 100px) * 0.8);
		width: 90%;
		margin: auto;
		margin-bottom: 2rem;
	}
}
.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(/static/media/enclosure1.137a723f.jpg) no-repeat center center fixed;
  background-size: cover;
  padding: 2rem;  background-position: center;
  width: 100%;
  min-height: 100vh;
  background-attachment: fixed;
}


.hours {
  font-size: 2rem;
}
.contact-whole {
  display: flex;
flex-direction: row;
  width: 75%;
  height: 80%;
  margin: 3rem auto;
  border-radius: 10px;
	box-shadow: 0 0 15px rgba(0,0,0,0.3)

}

.contact-submit > h2{
  width: 90%;
  margin: 2rem auto;
	color: #fff;
	padding: 2rem;
	text-align: center;
	font-weight: 100;
	font-size: 30px;
	background: rgb(34, 45, 61);
	box-shadow: 0 0 10px rgba(0,0,0,0.8);
}

label {
  border-bottom: 2px;
}

.contact-info > h2 {
  width: 90%;
  margin: 2rem auto;
	color: #242f40;
	padding: 2rem;
	text-align: center;
	font-weight: 100;
	font-size: 30px;
	background: #fff;
	box-shadow: 0 0 10px rgba(0,0,0,0.8);
}



.contact-info {
  margin:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
	background: rgb(34, 45, 61);
  color: #fff;
  border-radius: 0 10px 10px 0;
	box-shadow: 0 0 15px rgba(0,0,0,0.3)

}

.contact-submit {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
  padding-bottom: 2rem;
  color: black;
  background: #fff;
  border-radius: 10px 0 0 10px;
  

}

.form-group {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
  list-style: none;
  line-height: 1.5em;
}

#submit-button {
  display: flex;
  width: 75%;
  margin: 1rem auto;
  justify-content: center;
	color: #fff;
	padding: 20px;
	text-align: center;
	font-weight: 100;
	font-size: 20px;
	background: #242f40;
	box-shadow: 0 0 10px rgba(0,0,0,0.8);
}


#submit-button:hover, #submit-button:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  -webkit-transform: translateY(-0.25em);
          transform: translateY(-0.25em);
}

input, textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
}

input[type=text]:focus {
  border: 3px solid #555;
}

.svg-container {
  display: flex;
  width: 50px;
  height: 50px;
  border: solid #fff 3px;
  border-radius: 50%;
  margin: 0.5rem;
}

.svg-contact{
display: flex;
margin: auto;
padding: 10%;
width: 70%;
height: 70%;
-webkit-filter: invert();
        filter: invert();
}

.contact-phone, .contact-email{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-phone > a, .contact-email > a {
  color: #fff;
  text-decoration: none;
  line-height: 50px;
  font-size: 25px;
}

.contact-phone > a:hover, .contact-email > a:hover {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  -webkit-transform: scale(1.05);
          transform: scale(1.05);

}




.contact-info > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto 1rem;
  font-size: 20px;
  padding: 1rem;
  list-style-position:inside;

}

@media screen and (max-width: 1440px) {

.contact-whole {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

}


@media screen and (max-width: 1200px) {
#email-tag{ font-size: 1rem;}


}

@media screen and (max-width: 968px) {

.container {
    display: flex;
  background-image: url(/static/media/enclosure1.137a723f.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-attachment: fixed;
}

  .contact-whole {
    display: flex;
    width: 100%;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 2rem auto;
  }

  .contact-submit {
    display: flex;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  .contact-submit > h2 {
    display: flex;
    justify-content: center;
  }

  .contact-info {
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin:  auto;
    border-radius: 0 0 10px 10px ;

  }

#email-tag {
  display: flex;
  font-size: 1rem;
  }

  .hours {
    font-size: 0.9rem;
     margin-bottom: 2rem;
     list-style-type:none;
  }

  .hours > li {
    line-height: 1.1rem;

    margin: 0.25rem;
  }

  } 


.faq-inner-container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url(/static/media/enclosure1.137a723f.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-attachment: fixed;
}

.locations {
  display: flex;
flex-direction: row;
padding: 1rem;
width: 50%;
justify-content: space-between;
  
}

.FAQ-header {
  padding: 0.5rem;
}

.faq-item {
  display: flex;
  flex-direction: column;
  width: 70%;
  background-color: #dcdcdd;
  list-style-position:inside;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 3px;
	box-shadow: 0 0 15px rgba(0,0,0,0.3)
}

.faq-item>h2 {
  display: flex;
  width: 100%;
  margin: 0 0 15px;
  font-size: large;
  font-weight: 600;
  line-height: 1.75rem;
  padding: 0.5rem;
  border-radius: 3px;
	box-shadow: 0 0 15px rgba(0,0,0,0.3);
}


@media (max-width: 900px) {
}

@media (max-width: 765px) {

  .locations {
    display: flex;
  flex-direction: column;
  }

  .FAQ-header {
    padding: 0.5rem;
    font-size: 1rem;
  }

  .faq-item {
    margin-top: 0.5rem;
    padding: 0.5rem;
    display: flex;
    width:  100%;
    height: auto;
  
  }

  .faq-item>h2 {
    display: flex;
    width: 100%;
    font-size: 1rem;
    line-height: 1rem;
    padding: 0.5rem;
  }

  .faq-item>p {
    display: flex;
font-size: 1rem;
  }

}
  
.site-footer {
  background: #242f40;
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
position: relative;
bottom: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem ;
  text-align: center;
  color: white;

}



.footer > h2 {
  color: white;
  font-size: 2rem;
}

.footer > h4 {
  color: white;
  font-size: large;
  font-style: unset;
}


 .site-footer > h4{
  display: flex;
  flex-direction: nowrap;
  align-items: center;
}


.site-footer a {
  color: white;
  text-decoration: none;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links li {
  display: flex;
  justify-content: center;
  align-items: center;
}


.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block
}

.site-footer .social-icons {
  text-align: right;
}

.site-footer .social-icons a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-left: 6px;
  margin-bottom: 6px;
  margin-right: 0;
  border-radius: 100%;
  background-color: #eceeef;
}

.copyright-text {
  margin: 0
}


@media (max-width:767px) {

  .site-footer .copyright-text,
  .site-footer .social-icons {
    text-align: center
  }
}

.social-icons {
  margin-bottom: 0;
  padding-top: 1rem;
  list-style: none
}

.social-icons li {
  display: flex;
  margin-bottom: 4px
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px
}

.social-icons a {
  background-color: #eceeef;
  color: #818a91;
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  width: 44px;
  height: 44px;
  text-align: center;
  margin-right: 8px;
  border-radius: 100%;
  transition: all .2s linear
}

.social-icons a:active,
.social-icons a:focus,
.social-icons a:hover {
  color: #fff;
  background-color: #29aafe
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px
}

.social-icons a.facebook:hover {
  background-color: #3b5998
}

.social-icons a.twitter:hover {
  background-color: #00aced
}

.social-icons a.linkedin:hover {
  background-color: #007bb6
}

.social-icons a.yelp:hover {
  background-color: #ea4c89
}

@media (max-width:767px) {

.footer-item {
  padding: 0 0.75rem; 
}

  .social-icons {
    display: flex;
    flex-direction: column;
    padding: 0.75rem;
  }


  .site-footer {
    display: 'flex';
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    font-size: smaller;
    padding: env(safe-area-inset-bottom, 50px);
overflow-y: hidden;
      }

      .site-footer .social-icons {
        display: flex;
      }
  }


.gallery {background-color: #fff;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  background-attachment: fixed;
}

.single-skeleton {
  border-radius: 10px;

}

.single {
  display: flex;
  border-radius: 2rem;

}

#gallery-header  {
    text-align: center;
        font-size:40px; font-weight:500; color:#242f40; letter-spacing:2px;
        text-transform: uppercase;
        display: grid;
        grid-template-columns: 1fr -webkit-max-content 1fr;
        grid-template-columns: 1fr max-content 1fr;
        grid-template-rows: 27px 0;
        grid-gap: 20px;
        align-items: center;
    }
    
    #gallery-header:after, #gallery-header:before {
        content: " ";
        display: block;
        border-bottom: 1px solid  #242f40;
        border-top: 1px solid #242f40;
        height: 5px;
      background-color:#f8f8f8;
    }


.galleryWrap {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px;
    gap: 15px;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin: auto;
    background-color: none;
    padding: 2rem 1rem;
  }
  
  .galleryWrap .single {
    display: flex;
    width: 25%;
    height: 300px;
    background: none;
    cursor: pointer;
    object-fit: fill;
    justify-content: center;
    float: left;
  }

  
  .galleryWrap .single img {
    max-width: 100%;
    max-height: auto;
  }
  
  .galleryWrap .single img:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
      box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
  }
  
  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
    width: 50px;
    height: 50px;

  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 40px;
    right: 40px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */ /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
  }

  img ~ * {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  @media only screen and (min-width: 1200px) {
    .content__blog__container {
      width: 84%;
    }
  }
  
  @media only screen and (min-width: 1024px) {
    .cards__items {
      display: flex;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .cards__item {
      margin-bottom: 2rem;
    }
  }
    @media screen and (max-width: 768px){

      #gallery-header {
        font-size: 100%;
      }
     
      .galleryWrap {
     flex-direction: column;
     width: 100%;
     margin: auto;
    }

      .galleryWrap .single {
        width: 100%;
      }
    }

.link-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    height: 4rem;
    margin: 1rem auto;
    font-size: 1.5rem;
}

.link-gallery {
    text-decoration: none;
    color:#242f40;
    padding: 1rem;
    font-size: 1.1rem;
    line-height: 1.1rem;
    justify-content: center;
    align-items: top;
}

.link-gallery:hover {
    border-bottom: 4px solid #242f40;
    transition: all 0.2s ease-out;
    transition: 250ms;
  }

.single >img {
border-radius: 5px;
box-shadow: 0 0 10px rgba(0,0,0,0.3)
}


.single-skeleton {
    display: flex;
    justify-content: center ;
}


@media screen and (max-width: 748px) {

    .link-container {
        width: 90%;
        display: flex;
        flex-direction: start;
        justify-content: center;
    }


.link-gallery {
    display: block;
    width: 100%;
    flex-wrap: wrap;
    font-size: 1rem;
padding: .3rem;
justify-items: center;
}
}
