
.link-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    height: 4rem;
    margin: 1rem auto;
    font-size: 1.5rem;
}

.link-gallery {
    text-decoration: none;
    color:#242f40;
    padding: 1rem;
    font-size: 1.1rem;
    line-height: 1.1rem;
    justify-content: center;
    align-items: top;
}

.link-gallery:hover {
    border-bottom: 4px solid #242f40;
    transition: all 0.2s ease-out;
    transition: 250ms;
  }

.single >img {
border-radius: 5px;
-webkit-box-shadow: 0 0 10px rgba(0,0,0,0.3);
box-shadow: 0 0 10px rgba(0,0,0,0.3)
}


.single-skeleton {
    display: flex;
    justify-content: center ;
}


@media screen and (max-width: 748px) {

    .link-container {
        width: 90%;
        display: flex;
        flex-direction: start;
        justify-content: center;
    }


.link-gallery {
    display: block;
    width: 100%;
    flex-wrap: wrap;
    font-size: 1rem;
padding: .3rem;
justify-items: center;
}
}