.container {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url('../../src/images/enclosure1.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding: 2rem;  background-position: center;
  width: 100%;
  min-height: 100vh;
  background-attachment: fixed;
}


.hours {
  font-size: 2rem;
}
.contact-whole {
  display: flex;
flex-direction: row;
  width: 75%;
  height: 80%;
  margin: 3rem auto;
  border-radius: 10px;
	-webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
	        box-shadow: 0 0 15px rgba(0,0,0,0.3)

}

.contact-submit > h2{
  width: 90%;
  margin: 2rem auto;
	color: #fff;
	padding: 2rem;
	text-align: center;
	font-weight: 100;
	font-size: 30px;
	background: rgb(34, 45, 61);
	-webkit-box-shadow: 0 0 10px rgba(0,0,0,0.7);
	        box-shadow: 0 0 10px rgba(0,0,0,0.8);
}

label {
  border-bottom: 2px;
}

.contact-info > h2 {
  width: 90%;
  margin: 2rem auto;
	color: #242f40;
	padding: 2rem;
	text-align: center;
	font-weight: 100;
	font-size: 30px;
	background: #fff;
	-webkit-box-shadow: 0 0 10px rgba(0,0,0,0.7);
	        box-shadow: 0 0 10px rgba(0,0,0,0.8);
}



.contact-info {
  margin:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
	background: rgb(34, 45, 61);
  color: #fff;
  border-radius: 0 10px 10px 0;
	-webkit-box-shadow: 0 0 15px rgba(0,0,0,0.3);
	        box-shadow: 0 0 15px rgba(0,0,0,0.3)

}

.contact-submit {
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 50%;
  height: 100%;
  padding-bottom: 2rem;
  color: black;
  background: #fff;
  border-radius: 10px 0 0 10px;
  

}

.form-group {
  display: flex;
  flex-direction: column;
  width: 75%;
  margin: auto;
  list-style: none;
  line-height: 1.5em;
}

#submit-button {
  display: flex;
  width: 75%;
  margin: 1rem auto;
  justify-content: center;
	color: #fff;
	padding: 20px;
	text-align: center;
	font-weight: 100;
	font-size: 20px;
	background: #242f40;
	-webkit-box-shadow: 0 0 10px rgba(0,0,0,0.7);
	        box-shadow: 0 0 10px rgba(0,0,0,0.8);
}


#submit-button:hover, #submit-button:focus {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: translateY(-0.25em);
}

input, textarea {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
  border-radius: 4px;
  border: none;
}

input[type=text]:focus {
  border: 3px solid #555;
}

.svg-container {
  display: flex;
  width: 50px;
  height: 50px;
  border: solid #fff 3px;
  border-radius: 50%;
  margin: 0.5rem;
}

.svg-contact{
display: flex;
margin: auto;
padding: 10%;
width: 70%;
height: 70%;
filter: invert();
}

.contact-phone, .contact-email{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.contact-phone > a, .contact-email > a {
  color: #fff;
  text-decoration: none;
  line-height: 50px;
  font-size: 25px;
}

.contact-phone > a:hover, .contact-email > a:hover {
  box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
  transform: scale(1.05);

}




.contact-info > ul {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto 1rem;
  font-size: 20px;
  padding: 1rem;
  list-style-position:inside;

}

@media screen and (max-width: 1440px) {

.contact-whole {
  height: fit-content;
}

}


@media screen and (max-width: 1200px) {
#email-tag{ font-size: 1rem;}


}

@media screen and (max-width: 968px) {

.container {
    display: flex;
  background-image: url('../../src/images/enclosure1.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: fit-content;
  background-attachment: fixed;
}

  .contact-whole {
    display: flex;
    width: 100%;
  flex-direction: column;
  height: fit-content;
  margin: 2rem auto;
  }

  .contact-submit {
    display: flex;
    height: fit-content;
    width: 100%;
    border-radius: 10px 10px 0 0;
  }

  .contact-submit > h2 {
    display: flex;
    justify-content: center;
  }

  .contact-info {
    display: flex;
    width: 100%;
    height: fit-content;
    margin:  auto;
    border-radius: 0 0 10px 10px ;

  }

#email-tag {
  display: flex;
  font-size: 1rem;
  }

  .hours {
    font-size: 0.9rem;
     margin-bottom: 2rem;
     list-style-type:none;
  }

  .hours > li {
    line-height: 1.1rem;

    margin: 0.25rem;
  }

  } 