
.container-gallery {
	background-color: #fff;
	padding-bottom: 3rem;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 2rem;

}

.wrap {

	display: grid;
	padding: 3rem auto;
	grid-template-columns: 0.1fr repeat(5, 1fr) 0.1fr;
	grid-column-gap: 1rem;

}

.box {
	margin: 2rem auto;
	width: 100%;
	height: 80vh;
	text-align: center;
	text-decoration: none;
	border-radius: 10px;

}

.box:hover {
	margin-bottom: -5px;
	-webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
	-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

	transform: scale(1.015);

}


.box h1 {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	color: #fff;
	height: 20%;
	align-items: center;
	justify-content: center;
	margin-top: 50px;
	padding: 5px;
	text-align: center;
	font-weight: 100;
	font-size: 30px;
	background: rgba(0, 0, 0, 0.8);
	-webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.7);
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.8);
}


.one {
	grid-column: 2 / 2;
	background: url('../images/frameless4.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	object-fit: fill;
	background-position: center;

}

.two {
	grid-column: 3 / 3;
	background: url('../images/semi1.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	object-fit: scale-down;
	background-position: center;
}

.three {
	grid-column: 4 / 4;
	background: url('../images/sliding1.jpg');
	background-repeat: no-repeat;
	background-size: cover ;
	object-fit: scale-down;
	background-position: center;
}

.five {
	grid-column: 5 / 5;
	background: url('../images/mirror12.jpg');
	background-repeat: no-repeat;
	background-size: cover; 
	background-position:   right;
}

.six {
	grid-column: 6 / 6;
	background: url('../images/wine1.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}


@media screen and (max-width: 1200px) {
	.wrap {
		display: grid;
		width: 100%;
		display: grid;
		grid-template-columns: 5% repeat(2, 40%) 5%;
		grid-template-rows: repeat(3, 1fr);
		column-gap: 20px;
		row-gap: 20px;
	}

	.box {
		width: 100%;
	}

	.one {
		grid-column: 2 / 2;
		grid-row: 1 / 1;
	}


	.two {
		grid-column: 3 / 3;
		grid-row: 1 / 1;
	}


	.three {
		grid-column: 2 / 2;
		grid-row: 2 / 2;
	}


	.four {
		grid-column: 3 / 3;
		grid-row: 2 / 2;
	}

	.five {
		grid-column: 3 / 3;
		grid-row: 2 / 2;
	}

	.six {
		grid-column: 2 / 2;
		grid-row: 3 / 3;
	}
}

@media screen and (max-width: 480px) {
	.wrap {
		display: flex;
		flex-direction: column;
		padding: 1rem 0;
	}

	.box {
		height: calc((100vh - 100px) * 0.8);
		width: 90%;
		margin: auto;
		margin-bottom: 2rem;
	}
}