  .hero-container {
    background: url('../images/enclosure1.jpg') center center/cover no-repeat;
    height: calc(100vh - 100px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    font-size: large;
  }


.hero-image {
display: flex;
max-width: 80%;
height: auto;
padding-bottom: 1rem;

}
  
  .hero-container > p {
    color: #fff;
    display: flex;
    margin: 1rem;
    font-size: 2rem;
    text-align: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
      display: flex;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -125px;
    }
  }
  
  @media screen and (max-width: 768px) {

    .hero-container {
    background: url('../images/enclosure1.jpg') 25% center/cover no-repeat;
    }

    .hero-container > h1 {
      font-size: 50px;
  
    }

    .hero-image {
      display: flex;
      max-width: 60%;
      height: auto;
      
      }
  
    .hero-container > p {
      font-size: 20px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }