:root {
    --primary: #fff;
}

.btn {
    padding: 8px 20 px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
    white-space: nowrap;
}

.btn--primary {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
}

.btn--medium {
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large {
    padding: 12px 26px;
    font-size: 30px;
}

.btn--medium:hover, .btn--large:hover {
    background: white;
    color: #242424;
    transition: 250ms;
}